import { Entry } from 'contentful'
import { useServicesStore, useUserStore } from './store'
import { IPortalGeneralPageFields, Services } from '@estercare/ester-shared'
import { isServiceIdentifier } from '../utils/typeGuards'
import { Permission } from '../api/ester-b2b-api/private/users/types'

export const useSelectIsAdmin = () => useUserStore((state) => state.user?.permissions.includes('ADMIN'))
export const useSelectUserId = () => useUserStore((state) => state.user?.id)
export const useSelectUserFullName = () =>
  useUserStore((state) =>
    state.user?.firstName || state.user?.lastName ? `${state.user?.firstName} ${state.user?.lastName}` : undefined
  )
export const useSelectFirstName = () => useUserStore((state) => state.user?.firstName)
export const useSelectLastName = () => useUserStore((state) => state.user?.lastName)
export const useSelectUserEmail = () => useUserStore((state) => state.user?.email)
export const useSelectUserOrganisationId = () => useUserStore((state) => state.user?.organisationId)
export const useSelectUserOrganisationName = () => useUserStore((state) => state.user?.organisationName)
export const useIsUserSet = () => useUserStore((state) => !!state.user)

export const useSelectActiveServices = () => useServicesStore((state) => state.activeServices)

export const useSelectActiveServicesFromContentful = (
  ServiceIndex: Entry<IPortalGeneralPageFields>[],
  userRole: Permission
) => {
  return useServicesStore((state) => {
    const { activeServices } = state

    return ServiceIndex.filter((service) => {
      const { serviceIdentifier, role } = service.fields

      if (!isServiceIdentifier(serviceIdentifier)) {
        return false
      }
      if (userRole !== role) {
        return false
      }

      return activeServices.includes(serviceIdentifier as Services)
    })
  })
}

export const useSelectInactiveServicesFromContentful = (
  ServiceIndex: Entry<IPortalGeneralPageFields>[],
  userRole: Permission
) => {
  return useServicesStore((state) => {
    const { activeServices } = state

    return ServiceIndex.filter((service) => {
      const { serviceIdentifier, role } = service.fields

      if (!isServiceIdentifier(serviceIdentifier) || userRole !== role) {
        return false
      }

      return !activeServices.includes(serviceIdentifier as Services)
    })
  })
}
