import { useAuth0 } from '@auth0/auth0-react'
import {
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import {
  deleteUser,
  getUser,
  getUserAccessCode,
  getUsers,
  getUsersFromOrganisation,
  logIn,
  postUsers,
  updateUser,
} from './users'
import { User, UsersFromOrganisationDb, CreateUsersResponse, AccessCode } from './types'

export const useGetUsersQuery = (): UseQueryResult<User[], Error> => {
  const { getAccessTokenSilently } = useAuth0()

  return useQuery({
    queryKey: [QueryKeys.Users, 'private'],
    queryFn: async (): Promise<User[]> => {
      const accessToken = await getAccessTokenSilently()
      return await getUsers({ accessToken })
    },
  })
}

export const useGetUserQuery = ({
  id,
  options,
}: {
  id: string
  options?: Omit<UndefinedInitialDataOptions<User>, 'queryKey' | 'queryFn'>
}): UseQueryResult<User, Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.User, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getUser({ accessToken, id })
    },
    ...options,
  })
}

export const useGetUsersFromOrganisationQuery = (
  organisationId?: string
): UseQueryResult<UsersFromOrganisationDb[], Error> => {
  const { getAccessTokenSilently } = useAuth0()
  if (!organisationId) throw new Error('Missing required organisationId')

  return useQuery({
    queryKey: [QueryKeys.OrganisationUsers, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      const users: UsersFromOrganisationDb[] = await getUsersFromOrganisation({ accessToken, organisationId })

      return users
    },
    enabled: !!organisationId,
  })
}

export const useDeleteUserMutation = () => {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationKey: [QueryKeys.User, 'private'],
    mutationFn: async (id: string) => {
      const accessToken = await getAccessTokenSilently()
      await deleteUser({ accessToken, id })
    },
  })
}

export const usePostLoginQuery = ({
  options,
}: {
  options?: Omit<UseMutationOptions<User>, 'mutationKey' | 'mutationFn'>
}) => {
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationKey: [QueryKeys.User, 'private'],
    mutationFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await logIn({ accessToken })
    },
    ...options,
  })
}

export const usePostUsers = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation<CreateUsersResponse, Error, { usersFileData: FormData }>({
    mutationKey: [QueryKeys.User, 'private', 'uploadUsers'],
    mutationFn: async ({ usersFileData }) => {
      const accessToken = await getAccessTokenSilently()
      return await postUsers({ accessToken, usersFileData })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.OrganisationUsers, 'private'] })
    },
    onError: (error) => {
      throw new Error(`Failed to upload users ${error}`)
    },
  })
}

export const useGetUserAccessCodeQuery = (): UseQueryResult<AccessCode, Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.AccessCode, 'private'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getUserAccessCode({ accessToken })
    },
  })
}

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()

  return useMutation({
    mutationKey: [QueryKeys.User, 'private', 'patchUser'],
    mutationFn: async ({
      id,
      newUserData,
    }: {
      id: string
      newUserData: Partial<Omit<User, 'permission'>> & { permission?: 'ADMIN' | 'EMPLOYEE' }
    }) => {
      const accessToken = await getAccessTokenSilently()
      return await updateUser({ accessToken, id, newUserData })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.OrganisationUsers, 'private'] })
    },
    onError: (error) => {
      throw new Error(`Failed to upload users ${error}`)
    },
  })
}
